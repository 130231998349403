import { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Loading from "./components/shared/Loading/Loading";
import { Toaster } from "react-hot-toast";

const Landing = lazy(() => import("./pages/Landing/Landing"));
const Products = lazy(() => import("./pages/Pricing/Products"));
const PricingSales = lazy(() => import("./pages/PricingSales/PricingSales"));

function App() {
  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Toaster />
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route exact path="/pricing-sales" component={PricingSales} />
          <Route exact path="/products/:companyName" component={Products} />
          <Redirect to="/" />
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
